import { Suspense } from 'react';
import { Providers } from '../../layout/Providers';
import LoadingContainer from '../LoadingContainer';
import { AllRoutes } from '../Routes';
import { LicenseInfo } from '@mui/x-license-pro';

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE ?? '');

export const App = () => {
  return (
    <Suspense
      fallback={
        <LoadingContainer loading={true}>
          <></>
        </LoadingContainer>
      }
    >
      <Providers>
        <AllRoutes />
      </Providers>
    </Suspense>
  );
};
