import { Pages } from '../../pages';
import { createRoutes } from './routes-config';
import { useUserIsAuthenticated } from '../../contexts';

export const routes = createRoutes({
  fallback_route: '/login',
  routes: [
    { path: '/login', element: <Pages.Login /> },
    {
      path: '/',
      element: <Pages.Home />,
      authenticated: true,
    },
    { path: '/404', element: <Pages.NotFound /> },
    { path: '/help', element: <Pages.Help /> },
    {
      path: '/redirect/microsoft',
      element: <Pages.MicrosoftRedirect />,
    },
    {
      path: '/redirect/sso',
      element: <Pages.SSORedirect />,
    },
    { path: '*', element: <Pages.NotFound />, authenticated: true },
  ],
  useUserIsAuthenticated,
});
