import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from '../Theme';
import { AuthProvider } from '../../contexts/useUserIsAuthenticated';
import type { PropsWithChildren } from 'react';

export function Providers({ children }: PropsWithChildren<{}>) {
  return (
    <Router>
      <AuthProvider>
        <ThemeProvider>{children}</ThemeProvider>
      </AuthProvider>
    </Router>
  );
}
