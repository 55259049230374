import { Box, CircularProgress } from '@mui/material';

interface Props {
  loading: boolean;
  children: any;
  [key: string]: any;
}

const LoadingContainer = ({ loading, children, ...rest }: Props) => {
  if (loading) {
    return (
      <Box
        id="loadingContainer"
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ height: 'calc(100% - 76px)' }}
      >
        <CircularProgress aria-label="Loading" />
      </Box>
    );
  }

  return <Box {...rest}>{children}</Box>;
};

export default LoadingContainer;
