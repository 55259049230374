var e = Object.defineProperty,
  r = Object.defineProperties,
  o = Object.getOwnPropertyDescriptors,
  t = Object.getOwnPropertySymbols,
  i = Object.prototype.hasOwnProperty,
  n = Object.prototype.propertyIsEnumerable,
  a = (r, o, t) =>
    o in r ? e(r, o, { enumerable: !0, configurable: !0, writable: !0, value: t }) : (r[o] = t),
  l = (e, r) => {
    for (var o in r || (r = {})) i.call(r, o) && a(e, o, r[o]);
    if (t) for (var p of t(r)) n.call(r, p) && a(e, p, r[p]);
    return e;
  },
  c = (e, t) => r(e, o(t));
import * as u from 'react';
import p, { useMemo as d } from 'react';
import {
  getContrastRatio as s,
  lighten as h,
  darken as b,
  createTheme as f,
  alpha as g,
} from '@mui/system';
import { capitalize as x, alpha as m, useTheme as y } from '@mui/material';
import { deepmerge as k, formatMuiErrorMessage as M } from '@mui/utils';
const S =
  Object.assign ||
  function (e) {
    for (let r = 1; r < arguments.length; r++) {
      let o = arguments[r];
      for (let t in o) {
        if (Object.prototype.hasOwnProperty.call(o, t)) {
          e[t] = o[t];
        }
      }
    }
    return e;
  };
function w(e, r) {
  if (null == e) return {};
  var o,
    t,
    i = {},
    n = Object.keys(e);
  for (t = 0; t < n.length; t++) (o = n[t]), r.indexOf(o) >= 0 || (i[o] = e[o]);
  return i;
}
var v = { black: '#000', white: '#fff' };
var C = {
  50: '#fafafa',
  100: '#f5f5f5',
  200: '#eeeeee',
  300: '#e0e0e0',
  400: '#bdbdbd',
  500: '#9e9e9e',
  600: '#757575',
  700: '#616161',
  800: '#424242',
  900: '#212121',
  A100: '#f5f5f5',
  A200: '#eeeeee',
  A400: '#bdbdbd',
  A700: '#616161',
};
var O = {
  50: '#f3e5f5',
  100: '#e1bee7',
  200: '#ce93d8',
  300: '#ba68c8',
  400: '#ab47bc',
  500: '#9c27b0',
  600: '#8e24aa',
  700: '#7b1fa2',
  800: '#6a1b9a',
  900: '#4a148c',
  A100: '#ea80fc',
  A200: '#e040fb',
  A400: '#d500f9',
  A700: '#aa00ff',
};
var $ = {
  50: '#ffebee',
  100: '#ffcdd2',
  200: '#ef9a9a',
  300: '#e57373',
  400: '#ef5350',
  500: '#f44336',
  600: '#e53935',
  700: '#d32f2f',
  800: '#c62828',
  900: '#b71c1c',
  A100: '#ff8a80',
  A200: '#ff5252',
  A400: '#ff1744',
  A700: '#d50000',
};
var B = {
  50: '#fff3e0',
  100: '#ffe0b2',
  200: '#ffcc80',
  300: '#ffb74d',
  400: '#ffa726',
  500: '#ff9800',
  600: '#fb8c00',
  700: '#f57c00',
  800: '#ef6c00',
  900: '#e65100',
  A100: '#ffd180',
  A200: '#ffab40',
  A400: '#ff9100',
  A700: '#ff6d00',
};
var z = {
  50: '#e3f2fd',
  100: '#bbdefb',
  200: '#90caf9',
  300: '#64b5f6',
  400: '#42a5f5',
  500: '#2196f3',
  600: '#1e88e5',
  700: '#1976d2',
  800: '#1565c0',
  900: '#0d47a1',
  A100: '#82b1ff',
  A200: '#448aff',
  A400: '#2979ff',
  A700: '#2962ff',
};
var T = {
  50: '#e1f5fe',
  100: '#b3e5fc',
  200: '#81d4fa',
  300: '#4fc3f7',
  400: '#29b6f6',
  500: '#03a9f4',
  600: '#039be5',
  700: '#0288d1',
  800: '#0277bd',
  900: '#01579b',
  A100: '#80d8ff',
  A200: '#40c4ff',
  A400: '#00b0ff',
  A700: '#0091ea',
};
var F = {
  50: '#e8f5e9',
  100: '#c8e6c9',
  200: '#a5d6a7',
  300: '#81c784',
  400: '#66bb6a',
  500: '#4caf50',
  600: '#43a047',
  700: '#388e3c',
  800: '#2e7d32',
  900: '#1b5e20',
  A100: '#b9f6ca',
  A200: '#69f0ae',
  A400: '#00e676',
  A700: '#00c853',
};
const V = ['mode', 'contrastThreshold', 'tonalOffset'],
  _ = {
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.6)',
      disabled: 'rgba(0, 0, 0, 0.38)',
    },
    divider: 'rgba(0, 0, 0, 0.12)',
    background: { paper: v.white, default: v.white },
    action: {
      active: 'rgba(0, 0, 0, 0.54)',
      hover: 'rgba(0, 0, 0, 0.04)',
      hoverOpacity: 0.04,
      selected: 'rgba(0, 0, 0, 0.08)',
      selectedOpacity: 0.08,
      disabled: 'rgba(0, 0, 0, 0.26)',
      disabledBackground: 'rgba(0, 0, 0, 0.12)',
      disabledOpacity: 0.38,
      focus: 'rgba(0, 0, 0, 0.12)',
      focusOpacity: 0.12,
      activatedOpacity: 0.12,
    },
  },
  A = {
    text: {
      primary: v.white,
      secondary: 'rgba(255, 255, 255, 0.7)',
      disabled: 'rgba(255, 255, 255, 0.5)',
      icon: 'rgba(255, 255, 255, 0.5)',
    },
    divider: 'rgba(255, 255, 255, 0.12)',
    background: { paper: '#121212', default: '#121212' },
    action: {
      active: v.white,
      hover: 'rgba(255, 255, 255, 0.08)',
      hoverOpacity: 0.08,
      selected: 'rgba(255, 255, 255, 0.16)',
      selectedOpacity: 0.16,
      disabled: 'rgba(255, 255, 255, 0.3)',
      disabledBackground: 'rgba(255, 255, 255, 0.12)',
      disabledOpacity: 0.38,
      focus: 'rgba(255, 255, 255, 0.12)',
      focusOpacity: 0.12,
      activatedOpacity: 0.24,
    },
  };
function R(e, r, o, t) {
  const i = t.light || t,
    n = t.dark || 1.5 * t;
  e[r] ||
    (Object.prototype.hasOwnProperty.call(e, o)
      ? (e[r] = e[o])
      : 'light' === r
      ? (e.light = h(e.main, i))
      : 'dark' === r && (e.dark = b(e.main, n)));
}
function W(e) {
  const { mode: r = 'light', contrastThreshold: o = 3, tonalOffset: t = 0.2 } = e,
    i = w(e, V),
    n =
      e.primary ||
      (function (e = 'light') {
        return 'dark' === e
          ? { main: z[200], light: z[50], dark: z[400] }
          : { main: z[700], light: z[400], dark: z[800] };
      })(r),
    a =
      e.secondary ||
      (function (e = 'light') {
        return 'dark' === e
          ? { main: O[200], light: O[50], dark: O[400] }
          : { main: O[500], light: O[300], dark: O[700] };
      })(r),
    l =
      e.error ||
      (function (e = 'light') {
        return 'dark' === e
          ? { main: $[500], light: $[300], dark: $[700] }
          : { main: $[700], light: $[400], dark: $[800] };
      })(r),
    c =
      e.info ||
      (function (e = 'light') {
        return 'dark' === e
          ? { main: T[400], light: T[300], dark: T[700] }
          : { main: T[700], light: T[500], dark: T[900] };
      })(r),
    u =
      e.success ||
      (function (e = 'light') {
        return 'dark' === e
          ? { main: F[400], light: F[300], dark: F[700] }
          : { main: F[800], light: F[500], dark: F[900] };
      })(r),
    p =
      e.warning ||
      (function (e = 'light') {
        return 'dark' === e
          ? { main: B[400], light: B[300], dark: B[700] }
          : { main: '#ED6C02', light: B[500], dark: B[900] };
      })(r);
  function d(e) {
    return s(e, A.text.primary) >= o ? A.text.primary : _.text.primary;
  }
  const h = ({
      color: e,
      name: r,
      mainShade: o = 500,
      lightShade: i = 300,
      darkShade: n = 700,
    }) => {
      if (
        (!(e = S({}, e)).main && e[o] && (e.main = e[o]),
        !Object.prototype.hasOwnProperty.call(e, 'main'))
      )
        throw new Error(M(11, r ? ` (${r})` : '', o));
      if ('string' != typeof e.main)
        throw new Error(M(12, r ? ` (${r})` : '', JSON.stringify(e.main)));
      return (
        R(e, 'light', i, t), R(e, 'dark', n, t), e.contrastText || (e.contrastText = d(e.main)), e
      );
    },
    b = { dark: A, light: _ };
  return k(
    S(
      {
        common: v,
        mode: r,
        primary: h({ color: n, name: 'primary' }),
        secondary: h({
          color: a,
          name: 'secondary',
          mainShade: 'A400',
          lightShade: 'A200',
          darkShade: 'A700',
        }),
        error: h({ color: l, name: 'error' }),
        warning: h({ color: p, name: 'warning' }),
        info: h({ color: c, name: 'info' }),
        success: h({ color: u, name: 'success' }),
        grey: C,
        contrastThreshold: o,
        getContrastText: d,
        augmentColor: h,
        tonalOffset: t,
      },
      b[r],
    ),
    i,
  );
}
const H = [
  'fontFamily',
  'fontSize',
  'fontWeightLight',
  'fontWeightRegular',
  'fontWeightMedium',
  'fontWeightBold',
  'htmlFontSize',
  'allVariants',
  'pxToRem',
];
const E = { textTransform: 'uppercase' },
  P = '"Roboto", "Helvetica", "Arial", sans-serif';
function G(e, r) {
  const o = 'function' == typeof r ? r(e) : r,
    {
      fontFamily: t = P,
      fontSize: i = 14,
      fontWeightLight: n = 300,
      fontWeightRegular: a = 400,
      fontWeightMedium: l = 500,
      fontWeightBold: c = 700,
      htmlFontSize: u = 16,
      allVariants: p,
      pxToRem: d,
    } = o,
    s = w(o, H),
    h = i / 14,
    b = d || ((e) => (e / u) * h + 'rem'),
    f = (e, r, o, i, n) => {
      return S(
        { fontFamily: t, fontWeight: e, fontSize: b(r), lineHeight: o },
        t === P ? { letterSpacing: ((a = i / r), Math.round(1e5 * a) / 1e5) + 'em' } : {},
        n,
        p,
      );
      var a;
    },
    g = {
      h1: f(n, 96, 1.167, -1.5),
      h2: f(n, 60, 1.2, -0.5),
      h3: f(a, 48, 1.167, 0),
      h4: f(a, 34, 1.235, 0.25),
      h5: f(a, 24, 1.334, 0),
      h6: f(l, 20, 1.6, 0.15),
      subtitle1: f(a, 16, 1.75, 0.15),
      subtitle2: f(l, 14, 1.57, 0.1),
      body1: f(a, 16, 1.5, 0.15),
      body2: f(a, 14, 1.43, 0.15),
      button: f(l, 14, 1.75, 0.4, E),
      caption: f(a, 12, 1.66, 0.4),
      overline: f(a, 12, 2.66, 1, E),
    };
  return k(
    S(
      {
        htmlFontSize: u,
        pxToRem: b,
        fontFamily: t,
        fontSize: i,
        fontWeightLight: n,
        fontWeightRegular: a,
        fontWeightMedium: l,
        fontWeightBold: c,
      },
      g,
    ),
    s,
    { clone: !1 },
  );
}
function I(...e) {
  return [
    `${e[0]}px ${e[1]}px ${e[2]}px ${e[3]}px rgba(0,0,0,0.2)`,
    `${e[4]}px ${e[5]}px ${e[6]}px ${e[7]}px rgba(0,0,0,0.14)`,
    `${e[8]}px ${e[9]}px ${e[10]}px ${e[11]}px rgba(0,0,0,0.12)`,
  ].join(',');
}
var L = [
  'none',
  I(0, 2, 1, -1, 0, 1, 1, 0, 0, 1, 3, 0),
  I(0, 3, 1, -2, 0, 2, 2, 0, 0, 1, 5, 0),
  I(0, 3, 3, -2, 0, 3, 4, 0, 0, 1, 8, 0),
  I(0, 2, 4, -1, 0, 4, 5, 0, 0, 1, 10, 0),
  I(0, 3, 5, -1, 0, 5, 8, 0, 0, 1, 14, 0),
  I(0, 3, 5, -1, 0, 6, 10, 0, 0, 1, 18, 0),
  I(0, 4, 5, -2, 0, 7, 10, 1, 0, 2, 16, 1),
  I(0, 5, 5, -3, 0, 8, 10, 1, 0, 3, 14, 2),
  I(0, 5, 6, -3, 0, 9, 12, 1, 0, 3, 16, 2),
  I(0, 6, 6, -3, 0, 10, 14, 1, 0, 4, 18, 3),
  I(0, 6, 7, -4, 0, 11, 15, 1, 0, 4, 20, 3),
  I(0, 7, 8, -4, 0, 12, 17, 2, 0, 5, 22, 4),
  I(0, 7, 8, -4, 0, 13, 19, 2, 0, 5, 24, 4),
  I(0, 7, 9, -4, 0, 14, 21, 2, 0, 5, 26, 4),
  I(0, 8, 9, -5, 0, 15, 22, 2, 0, 6, 28, 5),
  I(0, 8, 10, -5, 0, 16, 24, 2, 0, 6, 30, 5),
  I(0, 8, 11, -5, 0, 17, 26, 2, 0, 6, 32, 5),
  I(0, 9, 11, -5, 0, 18, 28, 2, 0, 7, 34, 6),
  I(0, 9, 12, -6, 0, 19, 29, 2, 0, 7, 36, 6),
  I(0, 10, 13, -6, 0, 20, 31, 3, 0, 8, 38, 7),
  I(0, 10, 13, -6, 0, 21, 33, 3, 0, 8, 40, 7),
  I(0, 10, 14, -6, 0, 22, 35, 3, 0, 8, 42, 7),
  I(0, 11, 14, -7, 0, 23, 36, 3, 0, 9, 44, 8),
  I(0, 11, 15, -7, 0, 24, 38, 3, 0, 9, 46, 8),
];
const j = ['duration', 'easing', 'delay'],
  D = {
    easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
    easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
    easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
    sharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
  },
  U = {
    shortest: 150,
    shorter: 200,
    short: 250,
    standard: 300,
    complex: 375,
    enteringScreen: 225,
    leavingScreen: 195,
  };
function N(e) {
  return `${Math.round(e)}ms`;
}
function X(e) {
  if (!e) return 0;
  const r = e / 36;
  return Math.round(10 * (4 + 15 * r ** 0.25 + r / 5));
}
function Z(e) {
  const r = S({}, D, e.easing),
    o = S({}, U, e.duration);
  return S(
    {
      getAutoHeightDuration: X,
      create: (e = ['all'], t = {}) => {
        const { duration: i = o.standard, easing: n = r.easeInOut, delay: a = 0 } = t;
        return (
          w(t, j),
          (Array.isArray(e) ? e : [e])
            .map(
              (e) =>
                `${e} ${'string' == typeof i ? i : N(i)} ${n} ${'string' == typeof a ? a : N(a)}`,
            )
            .join(',')
        );
      },
    },
    e,
    { easing: r, duration: o },
  );
}
var Y = {
  mobileStepper: 1e3,
  speedDial: 1050,
  appBar: 1100,
  drawer: 1200,
  modal: 1300,
  snackbar: 1400,
  tooltip: 1500,
};
const J = ['breakpoints', 'mixins', 'spacing', 'palette', 'transitions', 'typography', 'shape'];
function q(e = {}, ...r) {
  const { mixins: o = {}, palette: t = {}, transitions: i = {}, typography: n = {} } = e,
    a = w(e, J),
    l = W(t),
    c = f(e);
  let u = k(c, {
    mixins:
      ((p = c.breakpoints),
      c.spacing,
      (d = o),
      S(
        {
          toolbar: {
            minHeight: 56,
            [`${p.up('xs')} and (orientation: landscape)`]: { minHeight: 48 },
            [p.up('sm')]: { minHeight: 64 },
          },
        },
        d,
      )),
    palette: l,
    shadows: L.slice(),
    typography: G(l, n),
    transitions: Z(i),
    zIndex: S({}, Y),
  });
  var p, d;
  return (u = k(u, a)), (u = r.reduce((e, r) => k(e, r), u)), u;
}
function K(e) {
  return String(parseFloat(e)).length === String(e).length;
}
function Q(e) {
  return parseFloat(e);
}
function ee(e) {
  return (r, o) => {
    const t = String(r).match(/[\d.\-+]*\s*(.*)/)[1] || '';
    if (t === o) return r;
    let i = Q(r);
    'px' !== t && ('em' === t || 'rem' === t) && (i = Q(r) * Q(e));
    let n = i;
    if ('px' !== o)
      if ('em' === o) n = i / Q(e);
      else {
        if ('rem' !== o) return r;
        n = i / Q(e);
      }
    return parseFloat(n.toFixed(5)) + o;
  };
}
function re({ lineHeight: e, pixels: r, htmlFontSize: o }) {
  return r / (e * o);
}
function oe(e, r = {}) {
  const {
      breakpoints: o = ['sm', 'md', 'lg'],
      disableAlign: t = !1,
      factor: i = 2,
      variants: n = [
        'h1',
        'h2',
        'h3',
        'h4',
        'h5',
        'h6',
        'subtitle1',
        'subtitle2',
        'body1',
        'body2',
        'caption',
        'button',
        'overline',
      ],
    } = r,
    a = S({}, e);
  a.typography = S({}, a.typography);
  const l = a.typography,
    c = ee(l.htmlFontSize),
    u = o.map((e) => a.breakpoints.values[e]);
  return (
    n.forEach((e) => {
      const r = l[e],
        o = parseFloat(c(r.fontSize, 'rem'));
      if (o <= 1) return;
      const n = o,
        a = 1 + (n - 1) / i;
      let { lineHeight: p } = r;
      if (!K(p) && !t) throw new Error(M(6));
      K(p) || (p = parseFloat(c(p, 'rem')) / parseFloat(o));
      let d = null;
      t ||
        (d = (e) =>
          (function ({ size: e, grid: r }) {
            const o = e - (e % r),
              t = o + r;
            return e - o < t - e ? o : t;
          })({
            size: e,
            grid: re({
              pixels: 4,
              lineHeight: p,
              htmlFontSize: l.htmlFontSize,
            }),
          })),
        (l[e] = S(
          {},
          r,
          (function ({
            cssProperty: e,
            min: r,
            max: o,
            unit: t = 'rem',
            breakpoints: i = [600, 900, 1200],
            transform: n = null,
          }) {
            const a = { [e]: `${r}${t}` },
              l = (o - r) / i[i.length - 1];
            return (
              i.forEach((o) => {
                let i = r + l * o;
                null !== n && (i = n(i)),
                  (a[`@media (min-width:${o}px)`] = {
                    [e]: `${Math.round(1e4 * i) / 1e4}${t}`,
                  });
              }),
              a
            );
          })({
            cssProperty: 'fontSize',
            min: a,
            max: n,
            unit: 'rem',
            breakpoints: u,
            transform: d,
          }),
        ));
    }),
    a
  );
}
const te = (e, { palette: r, mixins: o }) => ({
    color: r[e].contrastText,
    backgroundColor: r[e].main,
    boxShadow: '0 0 0px rgba(0,0,0,.001)',
    transition: 'color 300ms ease, box-shadow 300ms ease',
    '&:hover': { backgroundColor: 'dark' === r.mode ? r[e].light : r[e].dark },
    '&:focus': l(
      { backgroundColor: 'dark' === r.mode ? r[e].light : r[e].dark },
      o._ncr_internal.both(e).main,
    ),
    '&:focus:hover': l(
      { backgroundColor: 'dark' === r.mode ? r[e].light : r[e].dark },
      o._ncr_internal.both(e).main,
    ),
    '&.Mui-focusVisible': l(
      { backgroundColor: 'dark' === r.mode ? r[e].light : r[e].dark },
      o._ncr_internal.groupContained(e).focusVisible,
    ),
    '&.MuiButtonGroup-groupedContained:focus': l({}, o._ncr_internal.both(e).main),
    '&.MuiButtonGroup-groupedContained:hover': l({}, o._ncr_internal.both(e).main),
    '&.MuiButtonGroup-groupedContained:focus:hover': l({}, o._ncr_internal.both(e).main),
    '&.MuiButtonGroup-groupedContained.Mui-focusVisible': l(
      { backgroundColor: 'dark' === r.mode ? r[e].light : r[e].dark },
      o._ncr_internal.groupContained(e).focusVisible,
    ),
  }),
  ie = (e) =>
    pe.map((r) => ({
      props: { variant: 'contained', color: r },
      style: te(r, e),
    }));
function ne(e, r, o) {
  return 'light' === o.palette.mode ? e(o.palette) : r(o.palette);
}
const ae = (e, r) => {
    const { palette: o, mixins: t } = r;
    return {
      borderStyle: 'solid',
      borderWidth: '1px',
      boxShadow: '0 0 0px rgba(0,0,0,.001)',
      transition: 'color 300ms ease, border-color 300ms ease, box-shadow 300ms ease',
      '&:hover': {
        color: ne(
          (r) => r[e].dark,
          (r) => r[e].light,
          r,
        ),
      },
      '&:focus': l({}, t._ncr_internal.bothOutline(e).main),
      '&:focus:hover': l({}, t._ncr_internal.bothOutline(e).main),
      '&.Mui-focusVisible': l({}, t._ncr_internal.bothOutline(e).focusVisible),
      '&.MuiButtonGroup-groupedOutlined:focus': l({}, t._ncr_internal.groupOutline(e)),
      '&.MuiButtonGroup-groupedOutlined:hover': l({}, t._ncr_internal.groupOutline(e)),
      '&.MuiButtonGroup-groupedOutlined:focus:hover': l({}, t._ncr_internal.groupOutline(e)),
      '&.MuiButtonGroup-groupedOutlined.Mui-focusVisible': l(
        {},
        t._ncr_internal.groupOutline(e, !0),
      ),
      '&.MuiButtonGroup-groupedOutlinedHorizontal:not(:last-child):focus + .MuiButtonGroup-groupedOutlinedHorizontal.MuiButtonGroup-groupedOutlinedHorizontal.MuiButton-outlined':
        { borderLeftColor: o[e].main },
      '&.MuiButtonGroup-groupedOutlinedHorizontal:not(:last-child):hover + .MuiButtonGroup-groupedOutlinedHorizontal.MuiButtonGroup-groupedOutlinedHorizontal.MuiButton-outlined':
        { borderLeftColor: o[e].main },
      '&.MuiButtonGroup-groupedOutlinedVertical:not(:last-child):focus + .MuiButtonGroup-groupedOutlinedVertical.MuiButtonGroup-groupedOutlinedVertical.MuiButton-outlined':
        { borderTopColor: o[e].main },
      '&.MuiButtonGroup-groupedOutlinedVertical:not(:last-child):hover + .MuiButtonGroup-groupedOutlinedVertical.MuiButtonGroup-groupedOutlinedVertical.MuiButton-outlined':
        { borderTopColor: o[e].main },
    };
  },
  le = (e) =>
    pe.map((r) => ({
      props: { variant: 'outlined', color: r },
      style: ae(r, e),
    })),
  ce = (e, r) => {
    const { mixins: o } = r;
    return {
      boxShadow: '0 0 0px rgba(0,0,0,.001)',
      transition: 'color 300ms ease, box-shadow 300ms ease',
      '&:hover': {
        color: ne(
          (r) => r[e].dark,
          (r) => r[e].light,
          r,
        ),
      },
      '&:focus': {
        color: ne(
          (r) => r[e].dark,
          (r) => r[e].light,
          r,
        ),
      },
      '&:focus:hover': {
        color: ne(
          (r) => r[e].dark,
          (r) => r[e].light,
          r,
        ),
      },
      '&.Mui-focusVisible': l(
        {
          color: ne(
            (r) => r[e].dark,
            (r) => r[e].light,
            r,
          ),
        },
        o._ncr_internal.bothOutline(e).focusVisible,
      ),
      '&.MuiButtonGroup-groupedText:focus': l({}, o._ncr_internal.groupText(e)),
      '&.MuiButtonGroup-groupedText:hover': l({}, o._ncr_internal.groupText(e)),
      '&.MuiButtonGroup-groupedText:focus:hover': l({}, o._ncr_internal.groupText(e)),
      '&.MuiButtonGroup-groupedText.Mui-focusVisible': l({}, o._ncr_internal.groupText(e, !0)),
    };
  },
  ue = (e) => pe.map((r) => ({ props: { variant: 'text', color: r }, style: ce(r, e) })),
  pe = ['primary', 'secondary', 'error', 'info', 'success', 'warning'];
function de(e) {
  return {
    defaultProps: {
      disableElevation: !0,
      disableFocusRipple: !0,
      disableRipple: !0,
      disableTouchRipple: !0,
    },
    variants: [...ie(e), ...le(e), ...ue(e)],
  };
}
function se(e) {
  return {
    defaultProps: { disableRipple: !0, disableTouchRipple: !0 },
    styleOverrides: {
      root: {
        '&.Mui-focusVisible': {
          boxShadow: `0px 0px 1px ${e.palette.grey[300]}`,
        },
      },
    },
  };
}
const he = (e, r) => (o) => {
    const t = o.fontSize || 'normal',
      i = 'small' === t ? ['16px', { margin: '2px' }] : ['18px', { margin: '1px' }];
    return u.createElement(
      'span',
      {
        className: `ncr${x(r)}-size${x(t)} ncr${x(r)}-wrapper`,
        style: l({ display: 'inline-flex' }, i[1]),
      },
      u.createElement(
        'svg',
        c(l({ width: i[0], height: i[0], viewBox: '0 0 24 24', fill: 'none' }, o), {
          xmlns: 'http://www.w3.org/2000/svg',
        }),
        u.createElement(e, null),
      ),
    );
  },
  be = {
    Checked: he(
      () =>
        u.createElement('path', {
          d: 'M5.33331 13.2911L9.43014 17.3882L18.6666 6.61178',
          stroke: 'currentColor',
          strokeWidth: '1.625',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }),
      'checkbox',
    ),
    Empty: he(() => u.createElement(u.Fragment, null), 'checkbox'),
    Indeterminate: he(
      () =>
        u.createElement('path', {
          d: 'M5.33331 11.1875C4.88458 11.1875 4.52081 11.5513 4.52081 12C4.52081 12.4487 4.88458 12.8125 5.33331 12.8125V11.1875ZM18.6666 12.8125C19.1154 12.8125 19.4791 12.4487 19.4791 12C19.4791 11.5513 19.1154 11.1875 18.6666 11.1875V12.8125ZM5.33331 12.8125H18.6666V11.1875H5.33331V12.8125Z',
          fill: 'currentColor',
        }),
      'checkbox',
    ),
  },
  fe = {
    Checked: he(
      () =>
        u.createElement('path', {
          d: 'M5.33331 13.2911L9.43014 17.3882L18.6666 6.61178',
          stroke: 'currentColor',
          strokeWidth: '1.625',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }),
      'radio',
    ),
    Empty: he(() => u.createElement(u.Fragment, null), 'radio'),
  };
function ge(e) {
  const { palette: r } = e;
  return {
    defaultProps: {
      disableFocusRipple: !0,
      disableRipple: !0,
      disableTouchRipple: !0,
      icon: p.createElement(be.Empty, null),
      checkedIcon: p.createElement(be.Checked, null),
      indeterminateIcon: p.createElement(be.Indeterminate, null),
    },
    styleOverrides: {
      root: {
        color: r.grey[600],
        '&:hover': { color: r.grey[700] },
        '& .ncrCheckbox-wrapper': {
          borderRadius: 4,
          borderWidth: 2,
          borderColor: r.grey[400],
          borderStyle: 'solid',
          backgroundColor: 'transparent',
          willChange: 'border-color, background-color',
          userSelect: 'none',
          contain: 'layout paint',
        },
        '&.MuiIconButton-root.Mui-focusVisible': {
          backgroundColor: 'transparent',
        },
        '&.Mui-focusVisible .ncrCheckbox-wrapper': {
          boxShadow: `0px 0px 0px 4px ${r.grey[300]}`,
          borderColor: r.grey[500],
          color: r.grey[500],
        },
        '& .ncrCheckbox-wrapper svg': { color: r.primary.contrastText },
        '&.Mui-checked': {
          '&:not(.Mui-disabled) .ncrCheckbox-wrapper': {
            borderColor: 'currentcolor',
            borderStyle: 'solid',
            backgroundColor: 'currentcolor',
          },
        },
        '&.Mui-disabled .ncrCheckbox-wrapper': {
          backgroundColor: r.grey[700],
          borderColor: r.grey[700],
          opacity: 0.3,
        },
      },
      colorPrimary: {
        color: r.primary.main,
        transition: 'color 300ms ease',
        '&:hover': {
          color: ne(
            (e) => e.primary.dark,
            (e) => e.primary.light,
            e,
          ),
        },
        '&.Mui-focusVisible .ncrCheckbox-wrapper': {
          boxShadow: `0px 0px 0px 4px ${g(r.primary.light, 0.5)}`,
          borderColor: r.primary.main,
          color: r.primary.main,
        },
      },
      colorSecondary: {
        color: r.secondary.main,
        transition: 'color 300ms ease',
        '&:hover': {
          backgroundColor: 'transparent',
          color: ne(
            (e) => e.secondary.dark,
            (e) => e.secondary.light,
            e,
          ),
        },
        '&.Mui-focusVisible .ncrCheckbox-wrapper': {
          boxShadow: `0px 0px 0px 4px ${g(r.secondary.light, 0.5)}`,
          borderColor: r.secondary.main,
          color: r.secondary.main,
        },
      },
      indeterminate: {
        '&:not(.Mui-disabled) .ncrCheckbox-wrapper': {
          borderColor: 'currentcolor',
          borderStyle: 'solid',
          backgroundColor: 'currentcolor',
        },
      },
    },
  };
}
function xe(e) {
  return {
    defaultProps: { disableUnderline: !0 },
    styleOverrides: {
      root: {
        borderRadius: e.shape.borderRadius,
        backgroundColor: 'transparent',
        boxShadow: `0px 0px 0px 1px ${ne(
          (e) => e.grey[400],
          (e) => e.grey[800],
          e,
        )}`,
        borderStyle: 'solid',
        borderWidth: 0,
        '&.MuiInputBase-root': { backgroundColor: 'transparent' },
        '&:hover': { boxShadow: `0px 0px 0px 1px ${e.palette.text.primary}` },
        '&.Mui-error, &.MuiInputBase-colorSecondary.Mui-error': {
          boxShadow: `0px 0px 0px 1px ${e.palette.error.main}`,
          '&.Mui-focused, &.Mui-active, &.Mui-focusVisible': {
            boxShadow: `0px 0px 0px 2px ${e.palette.error.main}`,
          },
        },
        '&.Mui-focused, &.Mui-active, &.Mui-focusVisible': {
          boxShadow: `0px 0px 0px 2px ${e.palette.primary.main}`,
          '&.MuiInputBase-colorSecondary': {
            boxShadow: `0px 0px 0px 2px ${e.palette.secondary.main}`,
          },
        },
      },
      focused: { borderWidth: 2, backgroundColor: 'transparent' },
      input: {
        backgroundColor: 'transparent',
        '&.Mui-focused, &.Mui-focusVisible, &.Mui-active': {
          backgroundColor: 'transparent !important',
        },
      },
    },
  };
}
function me(e) {
  const { palette: r } = e;
  return {
    defaultProps: {
      icon: p.createElement(fe.Empty, null),
      checkedIcon: p.createElement(fe.Checked, null),
    },
    styleOverrides: {
      root: {
        color: r.grey[500],
        '& .ncrRadio-wrapper': {
          borderRadius: '500px',
          borderWidth: 2,
          borderColor: r.grey[400],
          borderStyle: 'solid',
          backgroundColor: 'transparent',
          transition: 'background-color 300ms ease',
          userSelect: 'none',
          contain: 'layout paint',
        },
        '&.Mui-focusVisible': { boxShadow: 'none' },
        '&.Mui-checked': {
          '& .ncrRadio-wrapper': {
            background: 'currentcolor',
            borderColor: 'currentcolor',
          },
        },
        '&.Mui-disabled': {
          color: r.grey[400],
          '& .ncrRadio-wrapper': { opacity: 0.3 },
        },
        '&:hover': { background: 'none' },
      },
      colorPrimary: {
        color: r.primary.main,
        '& .ncrRadio-wrapper svg': { color: r.primary.contrastText },
        '&:hover, &.Mui-checked:hover': {
          backgroundColor: 'transparent',
          color: ne(
            (e) => e.primary.dark,
            (e) => e.primary.light,
            e,
          ),
        },
        '&.Mui-focusVisible .ncrRadio-wrapper': {
          borderColor: r.primary.dark,
          boxShadow: `0px 0px 0px 4px ${m(r.primary.light, 0.5)}`,
        },
      },
      colorSecondary: {
        color: r.secondary.main,
        '& .ncrRadio-wrapper svg': { color: r.secondary.contrastText },
        '&:hover, &.Mui-checked:hover': {
          backgroundColor: 'transparent',
          color: ne(
            (e) => e.secondary.dark,
            (e) => e.secondary.light,
            e,
          ),
        },
        '&.Mui-focusVisible .ncrRadio-wrapper': {
          borderColor: r.secondary.dark,
          boxShadow: `0px 0px 0px 4px ${m(r.secondary.light, 0.5)}`,
        },
      },
    },
  };
}
function ye({ palette: e }) {
  return {
    styleOverrides: {
      thumb: {
        height: 22,
        width: 22,
        marginTop: 0,
        marginLeft: 0,
        '&:not(.MuiSlider-thumbSizeSmall)': {
          backgroundColor: '#fff',
          boxShadow: `0px 4px 8px 0px rgba(0, 0, 0, 0.15), inset 0 0 1px 1px ${e.grey[300]}`,
        },
      },
      track: { border: 'none' },
      thumbSizeSmall: {
        height: 12,
        width: 12,
        boxShadow: 'none',
        transformOrigin: 'center',
        transition: 'transform 150ms',
        '&:hover, &.Mui-active, &.Mui-focusVisible': { boxShadow: 'none' },
        '&:hover, &.Mui-active': {
          transform: 'scale(150%) translateY(-35%) translateX(-35%)',
          '& > .MuiSlider-valueLabelOpen': {
            transform: 'scale(75%) translateY(-110%)',
          },
        },
      },
    },
  };
}
function ke({ palette: e }) {
  return {
    defaultProps: { focusRipple: !1 },
    styleOverrides: {
      root: {
        width: 52,
        height: 32,
        padding: 0,
        margin: 8,
        contain: 'strict',
        color: 'light' === e.mode ? e.grey[400] : e.grey[800],
      },
      switchBase: {
        padding: 3,
        '&.Mui-checked': {
          transform: 'translateX(20px)',
          '& + &.MuiSwitch-track': {
            backgroundColor: 'currentColor',
            opacity: 1,
            border: 'none',
          },
        },
        '&.Mui-disabled': {
          border: 'none',
          backgroundColor: 'none',
          opacity: 0.4,
          '&.Mui-checked': { opacity: 0.4 },
          '&.Mui-checked + .MuiSwitch-track, & + .MuiSwitch-track': {
            opacity: 0.2,
            border: 'none',
          },
          '& .MuiSwitch-thumb': { boxShadow: 'none' },
        },
        '&.Mui-focusVisible': {
          '& .MuiSwitch-thumb': { boxShadow: 'none' },
          '& + .MuiSwitch-track': {
            boxShadow: `inset 0px 0px 0px 1px ${e.grey[400]}, inset 0px 0px 0px 3px ${e.primary.contrastText}`,
          },
        },
        '&.Mui-focusVisible.Mui-checked': {
          '&.MuiSwitch-colorPrimary': {
            '& + .MuiSwitch-track': {
              boxShadow: `inset 0px 0px 0px 1px ${e.primary.main}, inset 0px 0px 0px 3px ${e.primary.contrastText}`,
            },
          },
          '&.MuiSwitch-colorSecondary': {
            '& + .MuiSwitch-track': {
              boxShadow: `inset 0px 0px 0px 1px ${e.secondary.main}, inset 0px 0px 0px 3px ${e.secondary.contrastText}`,
            },
          },
        },
      },
      thumb: {
        width: 26,
        height: 26,
        backgroundColor: 'white',
        border: `inset 2px solid ${e.grey[400]}`,
      },
      track: {
        borderRadius: 16,
        backgroundColor: 'currentColor',
        opacity: 1,
        transition: 'background-color 200ms ease, border 200ms ease',
        willChange: 'background-color, border, box-shadow',
      },
      colorPrimary: {
        '&.Mui-checked + .MuiSwitch-track': {
          color: e.primary.main,
          opacity: 1,
        },
      },
      colorSecondary: {
        '&.Mui-checked + .MuiSwitch-track': {
          color: e.secondary.main,
          opacity: 1,
        },
      },
      input: { backgroundColor: 'transparent' },
      sizeSmall: {
        height: 16,
        width: 28,
        '& .MuiSwitch-switchBase': {
          padding: 4,
          margin: -2,
          '&:hover': { backgroundColor: 'transparent' },
          '&.Mui-checked': { transform: 'translateX(12px)' },
        },
        '& .MuiSwitch-thumb': { width: 12, height: 12 },
        '& .MuiSwitch-track': { borderRadius: 8 },
      },
    },
  };
}
function Me({ palette: e }) {
  const r = (r) => ({
    300: 'grey' === r ? e.grey[300] : g(e[r].light, 0.2),
    400: 'grey' === r ? e.grey[400] : e[r].light,
    600: 'grey' === r ? e.grey[600] : e[r].dark,
    contrastText: 'grey' === r ? e.grey[50] : e[r].contrastText,
    800: 'grey' === r ? e.grey[800] : b(e[r].dark, 0.3),
  });
  return {
    bothContained: (e) => {
      const o = r(e);
      return {
        main: { boxShadow: `0px 0px 4px ${o[400]}, 0px 0px 6px ${o[400]}` },
        focusVisible: {
          boxShadow: `inset 0px 0px 0px 2px ${o[600]}, inset 0px 0px 0px 4px ${o.contrastText}`,
        },
      };
    },
    bothOutline: (e) => {
      const o = r(e);
      return {
        main: { boxShadow: `0px 0px 4px 0px ${o[300]}, 0px 0px 6px ${o[400]}` },
        focusVisible: { boxShadow: `inset 0px 0px 0px 2px ${o[400]}` },
      };
    },
    both: (e) => {
      const o = r(e);
      return {
        main: { boxShadow: `0px 0px 4px 0px ${o[300]}, 0px 0px 6px ${o[400]}` },
        focusVisible: { boxShadow: `inset 0px 0px 0px 2px ${o[800]}` },
      };
    },
    groupContained: (e, o = !1) => {
      const t = r(e);
      return {
        main: { boxShadow: `inset 0px 0px 0px ${o ? '2' : '1'}px ${t[600]}` },
        focusVisible: {
          boxShadow: `inset 0px 0px 0px 2px ${t[600]}, inset 0px 0px 0px 4px ${t.contrastText}`,
        },
      };
    },
    groupOutline: (e, o = !1) => ({
      boxShadow: `inset 0px 0px 0px ${o ? '2' : '0'}px ${r(e)[400]}`,
    }),
    groupText: (o, t = !1) => {
      const i = r(o);
      return {
        boxShadow: `inset 0px 0px 0px ${t ? '2' : '0'}px ${'dark' === e.mode ? i[800] : i[300]}`,
      };
    },
  };
}
const Se = (e) => {
    const r = e;
    return `${(0.185 * Math.exp(-0.1745 * r) - 0.0223).toFixed(4)}em`;
  },
  we = (e) => (e.letterSpacing ? e : c(l({}, e), { letterSpacing: Se(e.fontSize) }));
function ve(e, r = !0) {
  return (
    ((e) => {
      e.typography = oe(
        q({
          typography: {
            fontFamily: [
              'Inter',
              '-apple-system',
              'BlinkMacSystemFont',
              '"Segoe UI"',
              'Roboto',
              '"Helvetica Neue"',
              'Arial',
              'sans-serif',
              '"Apple Color Emoji"',
              '"Segoe UI Emoji"',
              '"Segoe UI Symbol"',
            ].join(','),
            h1: we({ fontWeight: 700, fontSize: 96, lineHeight: 1.167 }),
            h2: we({ fontWeight: 700, fontSize: 60, lineHeight: 1.2 }),
            h3: we({ fontWeight: 700, fontSize: 48, lineHeight: 1.167 }),
            h4: we({ fontWeight: 700, fontSize: 34, lineHeight: 1.235 }),
            h5: we({ fontWeight: 700, fontSize: 24, lineHeight: 1.334 }),
            h6: we({ fontWeight: 700, fontSize: 20, lineHeight: 1.6 }),
            subtitle1: we({ fontWeight: 400, fontSize: 16, lineHeight: 1.75 }),
            subtitle2: we({ fontWeight: 500, fontSize: 14, lineHeight: 1.57 }),
            body1: we({ fontWeight: 400, fontSize: 16, lineHeight: 1.5 }),
            body2: we({ fontWeight: 400, fontSize: 14, lineHeight: 1.43 }),
            button: we({
              fontWeight: 500,
              fontSize: 14,
              lineHeight: 1.75,
              textTransform: 'none',
            }),
            caption: we({ fontWeight: 400, fontSize: 12, lineHeight: 1.66 }),
            overline: we({
              fontWeight: 400,
              fontSize: 12,
              lineHeight: 2.66,
              letterSpacing: 1,
            }),
          },
        }),
      ).typography;
    })(e),
    (e.mixins = c(l({}, e.mixins), { _ncr_internal: Me(e) })),
    r &&
      ((e) => {
        const r = e.spacing;
        (e.spacing = q({}).spacing),
          (e.components = {
            MuiAppBar: {
              defaultProps: {
                color: 'inherit',
                variant: 'outlined',
                elevation: 0,
              },
            },
            MuiButton: de(e),
            MuiButtonBase: se(e),
            MuiButtonGroup: {
              defaultProps: {
                disableElevation: !0,
                disableFocusRipple: !0,
                disableRipple: !0,
              },
            },
            MuiCheckbox: ge(e),
            MuiCard: { defaultProps: { elevation: 0, variant: 'outlined' } },
            MuiFilledInput: xe(e),
            MuiIconButton: {
              defaultProps: {
                disableRipple: !0,
                disableFocusRipple: !0,
                disableTouchRipple: !0,
                focusRipple: !1,
              },
            },
            MuiInputLabel: {
              styleOverrides: {
                filled: {
                  '&:not(.MuiInputLabel-shrink):not(.MuiInputLabel-sizeSmall)': {
                    transform: 'translate(12px, 18px) scale(1)',
                  },
                },
              },
            },
            MuiPaper: { defaultProps: { variant: 'outlined', elevation: 0 } },
            MuiRadio: me(e),
            MuiSlider: ye(e),
            MuiSwitch: ke(e),
            MuiTextField: { defaultProps: { variant: 'filled' } },
          }),
          (e.spacing = r);
      })(e),
    e
  );
}
const Ce = (e = {}) =>
  ((e, r = !1) => (
    (function (e = {}) {
      e.shape || (e.shape = { borderRadius: 8 });
    })(e),
    (function (e = {}) {
      var r;
      const o =
          'dark' === (null == (r = null == e ? void 0 : e.palette) ? void 0 : r.mode)
            ? 'dark'
            : 'light',
        t =
          'dark' === o
            ? { main: '#64B5F5', contrastText: '#000000' }
            : { main: '#1B76D4', contrastText: '#FFFFFF' },
        i =
          'dark' === o
            ? { main: '#BDBDBD', contrastText: '#000000' }
            : { main: '#757575', contrastText: '#FFFFFF' };
      e.palette || (e.palette = {}),
        e.palette.primary || (e.palette.primary = t),
        e.palette.secondary || (e.palette.secondary = i);
    })(e),
    ve(q(e), !r)
  ))(e);
function Oe(e) {
  return () => {
    const r = y();
    return d(() => e(r), [r, e]);
  };
}
export { Ce as createTheme, Ce as default, Oe as makeStyles };
