import { lazy } from 'react';

export const Pages = {
  Home: lazy(() => import('./Home')),
  Login: lazy(() => import('./Login')),
  MicrosoftRedirect: lazy(() => import('./MicrosoftRedirect')),
  NotFound: lazy(() => import('./NotFound')),
  Help: lazy(() => import('./Help')),
  SSORedirect: lazy(() => import('./SSORedirect')),
};
