import { ReactNode } from 'react';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { Snackbar } from '@mui/material';
import type { SnackbarProps } from '@mui/material';

interface Props extends SnackbarProps {
  content: string | ReactNode;
  show: boolean | true;
  onClose?: () => void;
}
const ErrorAlert = ({ content, show, onClose, ...rest }: Props) => {
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      open={show}
      autoHideDuration={6000}
      sx={{ minWidth: '300px' }}
      {...rest}
    >
      <Alert variant="filled" severity="error" onClose={onClose}>
        <AlertTitle>{content}</AlertTitle>
      </Alert>
    </Snackbar>
  );
};

export default ErrorAlert;
