import React, { PropsWithChildren } from 'react';
import { createContext, useState, useContext, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router';
import axios from 'axios';
import { ErrorAlert } from '../components/Alerts';

interface SSOLoginResponse {
  redirectUri: string;
  username: string;
}

type IAuthContext = {
  isAuthenticated: boolean;
  login: (username: string, password: string, port: string) => Promise<string | null>;
  microsoftLogin: (code: string) => Promise<SSOLoginResponse | null>;
  myncrSSOLogin: (code: string) => Promise<SSOLoginResponse | null>;
  logout: () => Promise<void> | undefined;
};

const initial_auth_context = {
  isAuthenticated: false,
  login: async () => null,
  logout: async () => void 0,
  microsoftLogin: async () => null,
  myncrSSOLogin: async () => null,
};

const AuthContext = createContext<IAuthContext>(initial_auth_context);

const FUNCTION_URL = process.env.REACT_APP_API_URL ?? 'http://localhost:7071';

const useCurrentLocalStorage = (): {
  status: string | null;
  username: string | null;
  locale: string | null;
} => ({
  status: localStorage.getItem('status'),
  username: localStorage.getItem('username'),
  locale: localStorage.getItem('locale'),
});

export const AuthProvider = ({ children }: PropsWithChildren<{}>) => {
  const navigate = useNavigate();
  const currentLocalStorage = useCurrentLocalStorage();

  const [isAuthenticated, __setIsAuthenticatedInternal] = useState<boolean>(
    Boolean(currentLocalStorage.status),
  );
  const [alertMessage, __setAlertMessage] = useState<React.ReactElement | string>('');
  const [{ showErrorAlert }, __setAlertStates] = useState({
    showErrorAlert: false,
  });

  const setLoggedIn = useCallback(
    ({ status, username, locale }: { status: string; username: string; locale: string }) => {
      if (isAuthenticated) return; // do nothing if logged in
      localStorage.setItem('status', status);
      localStorage.setItem('username', username);
      localStorage.setItem('locale', locale);
      __setAlertStates({ showErrorAlert: false });
      __setIsAuthenticatedInternal(true);
    },
    [isAuthenticated, __setIsAuthenticatedInternal, __setAlertStates],
  );

  const setLoginError = useCallback(
    (message: React.ReactElement | string) => {
      if (isAuthenticated) {
        __setAlertStates({ showErrorAlert: false });
        return;
      }
      __setAlertMessage(message);
      __setAlertStates({ showErrorAlert: true });
    },
    [isAuthenticated, __setAlertStates],
  );

  const setLoggedOut = useCallback(
    (redirect: boolean) => {
      if (!isAuthenticated) return;
      localStorage.clear();
      __setIsAuthenticatedInternal(false);
      if (redirect) {
        navigate('/login');
      }
    },
    [isAuthenticated, __setIsAuthenticatedInternal, __setAlertStates],
  );

  const removeErrors = useCallback(() => {
    __setAlertStates({ showErrorAlert: false });
  }, [__setAlertStates]);

  const login = useCallback(
    async (username: string, password: string, port: string) => {
      let tokenData;
      const url = port ? `http://localhost:${port}/auth/myncr/` : `${FUNCTION_URL}/auth/myncr`;
      let reqOptions = {
        url: url,
        method: 'POST',
        data: {
          identifier: username.toLowerCase(),
          password,
        },
        withCredentials: true,
      };
      try {
        tokenData = await axios.request(reqOptions);

        if (tokenData.status !== 200 || !tokenData) {
          setLoginError('Invalid Credentials - Username and/or password do not match');
          return null;
        }
        setLoggedIn({
          username: username,
          status: 'authenticated',
          locale: 'en',
        });
        return tokenData.data.redirectUri;
      } catch (e: any) {
        const error = e.response ? e.response.data : e;
        if (e.response.data.message === 'Failed to validate credentials in OpenAM') {
          setLoginError('Invalid Credentials - Username and/or password do not match');
        } else {
          setLoginError(e.response.data.message); // fallthrough case
        }
        return null;
      }
    },
    [setLoginError, setLoggedIn],
  );

  const microsoftLogin = useCallback(
    async (code: string) => {
      try {
        if (!code) {
          throw 'No authorization code';
        }
        const { data } = await axios.get(`${FUNCTION_URL}/auth/microsoft/callback?code=${code}`, {
          withCredentials: true,
        });
        if (!data.username) {
          throw 'No data received from response';
        }
        setLoggedIn({
          username: data.username,
          status: 'Authenticated',
          locale: 'en',
        });
        return {
          redirectUri: data.redirectUri,
          username: data.username,
        };
      } catch (e: any) {
        const error = e.response ? e.response.data : e;
        console.log(`Error in callback request: ${error}`);
        setLoginError('Unable to authenticate user');
        return null;
      }
    },
    [setLoginError, setLoggedIn],
  );

  const myncrSSOLogin = useCallback(
    async (code: string) => {
      try {
        if (!code) {
          throw 'No authorization code';
        }
        const { data } = await axios.get(`${FUNCTION_URL}/auth/sso?code=${code}`, {
          withCredentials: true,
        });
        if (!data.username) {
          throw 'No data received from response';
        }
        setLoggedIn({
          username: data.username,
          status: 'Authenticated',
          locale: 'en',
        });
        return {
          redirectUri: data.redirectUri,
          username: data.username,
        };
      } catch (e: any) {
        const error = e.response ? e.response.data : e;
        console.log(`Error in myncr callback request: ${error}`);
        setLoginError('Unable to authenticate user');
        return null;
      }
    },
    [setLoginError, setLoggedIn],
  );

  const logout = useCallback(async () => {
    try {
      await axios.post(`${FUNCTION_URL}/auth/logout`, { withCredentials: true });
    } catch (e: any) {
      console.log('Logout error', e.response.data);
    } finally {
      return setLoggedOut(true);
    }
  }, [setLoggedOut]);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        login,
        logout,
        microsoftLogin,
        myncrSSOLogin,
      }}
    >
      <ErrorAlert content={alertMessage} show={showErrorAlert} onClose={removeErrors} />
      {children}
    </AuthContext.Provider>
  );
};

export const useUserIsAuthenticated = (): boolean => {
  const { isAuthenticated } = useContext(AuthContext);
  return isAuthenticated;
};
export const useLogin = () => {
  const { login, microsoftLogin, myncrSSOLogin } = useContext(AuthContext);
  return { login, microsoftLogin, myncrSSOLogin };
};
export const useLogout = () => {
  const { logout } = useContext(AuthContext);
  return { logout };
};
